<template>
  <div>
    <!-- 检索 -->
    <el-form :inline="true" :model="form" ref="ruleForm">
      <el-form-item class="form-item" label="账号/昵称" prop="str_keyword">
        <el-input
          v-model="form.str_keyword"
          placeholder="请输入账号/昵称"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="标签/机构" prop="str_tag">
        <el-input
          v-model="form.str_tag"
          placeholder="请输入标签/机构"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="所在地区" prop="str_province">
        <el-cascader
          :options="options"
          :props="{
            checkStrictly: true,
            label: 'name',
            value: 'name',
            children: 'districts',
          }"
          v-model="str_province"
          clearable
          class="city-input"
        ></el-cascader>
      </el-form-item>
      <el-form-item class="form-item" label="所在年级" prop="str_level">
        <el-select v-model="form.str_level" placeholder="全部" clearable>
          <el-option label="幼儿园前" value="幼儿园前"></el-option>
          <el-option label="幼儿园" value="幼儿园"></el-option>
          <el-option label="小学一年级" value="小学一年级"></el-option>
          <el-option label="小学二年级" value="小学二年级"></el-option>
          <el-option label="小学三年级" value="小学三年级"></el-option>
          <el-option label="小学四年级" value="小学四年级"></el-option>
          <el-option label="小学五年级" value="小学五年级"></el-option>
          <el-option label="小学六年级" value="小学六年级"></el-option>
          <el-option label="初中年级" value="初中年级"></el-option>
          <el-option label="高中年级" value="高中年级"></el-option>
          <el-option label="大学年级" value="大学年级"></el-option>
          <el-option label="毕业工作" value="毕业工作"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="年龄" prop="str_age">
        <el-select v-model="form.str_age" placeholder="全部" clearable>
          <el-option label="3岁以下" value="1"></el-option>
          <el-option label="3~6" value="2"></el-option>
          <el-option label="7~9" value="3"></el-option>
          <el-option label="10~12" value="4"></el-option>
          <el-option label="13岁以上" value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="用户来源" prop="clienttype">
        <el-select v-model="form.clienttype" placeholder="全部" clearable>
          <el-option label="Uummii iOS" value="2"></el-option>
          <el-option label="Uummii Android" value="3"></el-option>
          <el-option label="Uummii iPad" value="4"></el-option>
          <el-option label="Uummii Android Pad" value="5"></el-option>
          <el-option label="纠音 iOS" value="11"></el-option>
          <el-option label="纠音 Android" value="12"></el-option>
          <el-option label="纠音 iPad" value="13"></el-option>
          <el-option label="Umi跟读小程序" value="9"></el-option>
          <el-option label="UmiShow" value="8"></el-option>
          <el-option label="一键参团" value="7"></el-option>
          <el-option label="服务号" value="1"></el-option>
          <el-option label="订阅号" value="0"></el-option>
          <el-option label="客服" value="6"></el-option>
          <el-option label="官网" value="10"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="用户所属" prop="user_type">
        <el-select
          v-model="user_type"
          multiple
          collapse-tags
          placeholder="全部"
          @change="handType"
        >
          <el-option label="Uummii App" value="1"></el-option>
          <el-option label="纠音 App" value="2"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item class="form-item" label="会员状态" prop="member_status">
        <el-select v-model="form.member_status" placeholder="全部" clearable>
          <el-option label="会员中" value="1"></el-option>
          <el-option label="会员已过期" value="2"></el-option>
          <el-option label="非会员" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="会员身份" prop="member_identity">
        <el-select v-model="form.member_identity" placeholder="全部" clearable>
          <el-option label="月卡" value="1"></el-option>
          <el-option label="季卡" value="2"></el-option>
          <el-option label="半年卡" value="3"></el-option>
          <el-option label="年卡" value="4"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item class="form-item" label="注册时间" prop="createdtime">
        <el-date-picker
          v-model="createdtime"
          :editable="false"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="date-input"
          @change="handCreateTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item" label="最近登陆时间" prop="modifiedtime">
        <el-date-picker
          v-model="modifiedtime"
          :editable="false"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="date-input"
          @change="handModifiedTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 检索 -->

    <div class="table-content">
      <div class="table-title">
        <div>用户列表</div>
      </div>

      <!-- 列表 -->
      <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column
          fixed="left"
          prop="username"
          label="昵称"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="tableid"
          label="用户ID"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="phone" label="账号" width="150" align="center">
        </el-table-column>
        <el-table-column
          prop="province"
          label="所在地区"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column label="年龄" width="150" align="center">
          <template #default="scope">
            <div v-if="scope.row.birthday || scope.row.birthday === 0">
              {{ scope.row.birthday }}岁
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="level"
          label="所在年级"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="origanization_name"
          label="所在机构"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column label="标签" width="250" align="center">
          <template #default="scope">
            <el-tag
              v-for="(item, index) in scope.row.tag_name"
              :key="index"
              effect="dark"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="createdtime"
          label="注册时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="clienttype"
          label="用户来源"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="modifiedtime"
          label="最近登陆时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column label="状态" width="150" align="center">
          <template #default="scope">
            <div>{{ scope.row.type }}</div>
            <div v-if="scope.row.type !== '非会员'" class="type-info">
              会员信息
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          show-overflow-tooltip
          min-width="200"
          align="center"
        >
          <template #default="scope">
            <el-button
              v-if="type.indexOf('详情') > -1"
              @click="handleClick(scope.row.id)"
              type="text"
              size="small"
              >查看</el-button
            >
            <el-button
              v-if="typeOrder"
              @click="handleOrder(scope.row.phone)"
              type="text"
              size="small"
              >订单信息</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 列表 -->

      <!-- 分页 -->
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
      <!-- 分页 -->
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import { addressList, studentList } from "@/api/apiList/user-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      createdtime: "",
      modifiedtime: "",
      form: {
        str_keyword: "",
        str_tag: "",
        str_province: "",
        str_level: "",
        str_age: "",
        clienttype: "",
        user_type: "",
        // member_status: "",
        // member_identity: "",
        createdtime: "",
        modifiedtime: "",
        pageSize: 10,
        page: 1,
      },
      str_province: "",
      user_type: [],
      options: [],
      tableData: [],
      type: "",
      typeOrder: false,
      loading: true,
      pageSize: 10,
      currentPage: 1,
      total: 10,
    };
  },
  methods: {
    handType(e) {
      this.form.user_type = e.toString();
    },
    handCreateTime(e) {
      if (e) {
        let start_m =
          new Date(e[0]).getMonth() + 1 > 9
            ? new Date(e[0]).getMonth() + 1
            : "0" + (new Date(e[0]).getMonth() + 1);
        let start_d =
          new Date(e[0]).getDate() > 9
            ? new Date(e[0]).getDate()
            : "0" + new Date(e[0]).getDate();
        let start_h =
          new Date(e[0]).getHours() > 9
            ? new Date(e[0]).getHours()
            : "0" + new Date(e[0]).getHours();
        let start_f =
          new Date(e[0]).getMinutes() > 9
            ? new Date(e[0]).getMinutes()
            : "0" + new Date(e[0]).getMinutes();
        let start_s =
          new Date(e[0]).getSeconds() > 9
            ? new Date(e[0]).getSeconds()
            : "0" + new Date(e[0]).getSeconds();
        let end_m =
          new Date(e[1]).getMonth() + 1 > 9
            ? new Date(e[1]).getMonth() + 1
            : "0" + (new Date(e[1]).getMonth() + 1);
        let end_d =
          new Date(e[1]).getDate() > 9
            ? new Date(e[1]).getDate()
            : "0" + new Date(e[1]).getDate();
        let end_h =
          new Date(e[1]).getHours() > 9
            ? new Date(e[1]).getHours()
            : "0" + new Date(e[1]).getHours();
        let end_f =
          new Date(e[1]).getMinutes() > 9
            ? new Date(e[1]).getMinutes()
            : "0" + new Date(e[1]).getMinutes();
        let end_s =
          new Date(e[1]).getSeconds() > 9
            ? new Date(e[1]).getSeconds()
            : "0" + new Date(e[1]).getSeconds();
        this.form.createdtime =
          new Date(e[0]).getFullYear() +
          "-" +
          start_m +
          "-" +
          start_d +
          " " +
          start_h +
          ":" +
          start_f +
          ":" +
          start_s +
          "~" +
          new Date(e[1]).getFullYear() +
          "-" +
          end_m +
          "-" +
          end_d +
          " " +
          end_h +
          ":" +
          end_f +
          ":" +
          end_s;
      } else {
        this.form.createdtime = "";
      }
    },
    handModifiedTime(e) {
      if (e) {
        let start_m =
          new Date(e[0]).getMonth() + 1 > 9
            ? new Date(e[0]).getMonth() + 1
            : "0" + (new Date(e[0]).getMonth() + 1);
        let start_d =
          new Date(e[0]).getDate() > 9
            ? new Date(e[0]).getDate()
            : "0" + new Date(e[0]).getDate();
        let start_h =
          new Date(e[0]).getHours() > 9
            ? new Date(e[0]).getHours()
            : "0" + new Date(e[0]).getHours();
        let start_f =
          new Date(e[0]).getMinutes() > 9
            ? new Date(e[0]).getMinutes()
            : "0" + new Date(e[0]).getMinutes();
        let start_s =
          new Date(e[0]).getSeconds() > 9
            ? new Date(e[0]).getSeconds()
            : "0" + new Date(e[0]).getSeconds();
        let end_m =
          new Date(e[1]).getMonth() + 1 > 9
            ? new Date(e[1]).getMonth() + 1
            : "0" + (new Date(e[1]).getMonth() + 1);
        let end_d =
          new Date(e[1]).getDate() > 9
            ? new Date(e[1]).getDate()
            : "0" + new Date(e[1]).getDate();
        let end_h =
          new Date(e[1]).getHours() > 9
            ? new Date(e[1]).getHours()
            : "0" + new Date(e[1]).getHours();
        let end_f =
          new Date(e[1]).getMinutes() > 9
            ? new Date(e[1]).getMinutes()
            : "0" + new Date(e[1]).getMinutes();
        let end_s =
          new Date(e[1]).getSeconds() > 9
            ? new Date(e[1]).getSeconds()
            : "0" + new Date(e[1]).getSeconds();
        this.form.modifiedtime =
          new Date(e[0]).getFullYear() +
          "-" +
          start_m +
          "-" +
          start_d +
          " " +
          start_h +
          ":" +
          start_f +
          ":" +
          start_s +
          "~" +
          new Date(e[1]).getFullYear() +
          "-" +
          end_m +
          "-" +
          end_d +
          " " +
          end_h +
          ":" +
          end_f +
          ":" +
          end_s;
      } else {
        this.form.modifiedtime = "";
      }
    },
    onSubmit() {
      this.handList();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.str_province = "";
      this.createdtime = "";
      this.modifiedtime = "";
    },
    handleClick(e) {
      this.$router.push({ name: "user_detail", params: { id: e } });
    },
    // 订单信息
    handleOrder(e) {
      this.$store.commit("changePhone", e);
      this.$router.push({ name: "order_list" });
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    // 用户列表
    handList() {
      this.loading = true;
      if (this.str_province) {
        if (
          this.str_province[0] === "北京" ||
          this.str_province[0] === "天津" ||
          this.str_province[0] === "上海" ||
          this.str_province[0] === "重庆"
        ) {
          if (this.str_province.length === 3) {
            this.form.str_province =
              this.str_province[0] + "市-" + this.str_province[2];
          } else {
            this.form.str_province = this.str_province[0] + "市";
          }
        } else {
          this.form.str_province = this.str_province.join("-");
        }
      } else {
        this.form.str_province = "";
      }
      studentList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            if (item.phone && this.type.indexOf("查看手机号") <= -1) {
              const reg = /^(\d{3})\d{4}(\d{4})$/;
              item.phone = item.phone.replace(reg, "$1****$2");
            }

            if (item.tag_name) {
              item.tag_name = item.tag_name.split(",");
            }

            switch (item.clienttype) {
              case 0:
                item.clienttype = "订阅号";
                break;
              case 1:
                item.clienttype = "服务号";
                break;
              case 2:
                item.clienttype = "Uummii iOS";
                break;
              case 3:
                item.clienttype = "Uummii Android";
                break;
              case 4:
                item.clienttype = "Uummii iPad";
                break;
              case 5:
                item.clienttype = "Uummii Android Pad";
                break;
              case 6:
                item.clienttype = "客服";
                break;
              case 7:
                item.clienttype = "一键参团";
                break;
              case 8:
                item.clienttype = "UmiShow";
                break;
              case 9:
                item.clienttype = "Umi跟读小程序";
                break;
              case 10:
                item.clienttype = "官网";
                break;
              case 11:
                item.clienttype = "纠音 iOS";
                break;
              case 12:
                item.clienttype = "纠音 Android";
                break;
              case 13:
                item.clienttype = "纠音 iPad";
                break;
            }

            switch (item.type) {
              case 0:
                item.type = "非会员";
                break;
              case 1:
                item.type = "非会员";
                break;
              case 2:
                item.type = "会员中";
                break;
              case 3:
                item.type = "会员中";
                break;
              case 4:
                item.type = "会员已过期";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
    // 是否有订单列表
    handTypeFc(Arr) {
      function flatten(arr) {
        return [].concat(
          ...arr.map((item) => {
            return item.children
              ? [].concat(item, ...flatten(item.children))
              : [].concat(item);
          })
        );
      }

      let typeArr = flatten(Arr).filter(
        (item) => item.function === "/order-list"
      );
      if (typeArr.length > 0) {
        this.typeOrder = true;
      } else {
        this.typeOrder = false;
      }
    },
  },
  mounted() {
    let arr = JSON.parse(localStorage.getItem("menuList"));
    this.handTypeFc(arr);
    this.type = this.$store.state.type;
    addressList().then((res) => {
      if (res.result === "200") {
        for (let index = 0; index < res.data.length; index++) {
          const arr = res.data[index].districts;
          for (let i = 0; i < arr.length; i++) {
            const array = res.data[index].districts[i].districts;
            for (let j = 0; j < array.length; j++) {
              const element = array[j];
              if (element.districts.length === 0) {
                delete element.districts;
              }
            }
          }
        }
        this.options = res.data;
      }
    });
    this.handList();
  },
};
</script>

<style lang="scss">
.form-item {
  .date-input {
    width: 360px;
  }

  .city-input {
    .el-input {
      width: 200px !important;
    }
  }
}

.type-info {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
